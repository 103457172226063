import React from "react";
import { MapContainer, TileLayer } from 'react-leaflet';
import { latLng, latLngBounds, circle } from 'leaflet';
import StaticPin from "Components/layout/container/map/StaticPin";
import FitBounds from "Components/layout/container/map/FitBounds";

// List of country where it should be allowed to place a pin. The codes
// should be according to ISO 3166-1.
const allowedCountries = ['nl'];

function Map(props) {

    let PlaceablePin = props.placeablePin ?? undefined;
    let Circle = props.circle ?? undefined;

    const staticPins = props.staticPins?.map((pin) =>
        <StaticPin
            lat={pin.latitude}
            lng={pin.longitude}
            key={pin.id}
            type={pin.is_nice_place ? "goodPin" : "badPin"}
            icon={pin.is_nice_place ? "goodPin" : "badPin"}
            size={32}
            onClick={() => props.onStaticPinClick(pin.id)}
        />
    ) ?? [];

    return (
        <MapContainer
            className='leaflet-container'
            center={[props.centerLat, props.centerLng]}
            zoomControl={props.zoomControl}
            scrollWheelZoom={props.scrollWheelZoom}
            dragging={props.dragging}
            zoom={1}>
            {/* maxBounds={props.props.maxBounds} */}
            {props.projectArea ?
                <FitBounds projectArea={props.projectArea} />
                : <FitBounds mapMaxBounds={props.maxBounds} />}
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {Circle !== undefined &&
                <Circle
                    circleLatListener={props.circleLatListener}
                    circleLngListener={props.circleLngListener}
                    circleRadiusListener={props.circleRadiusListener}
                />
            }
            {staticPins}
            {PlaceablePin !== undefined &&
                <PlaceablePin mapMaxBounds={props.maxBounds} allowedCountries={allowedCountries} handleUpdatePin={props.handleUpdatePin} projectArea={props.projectArea ?? undefined} />
            }
        </MapContainer>
    )
}

Map.defaultProps = {
    centerLat: '52.156111',
    centerLng: '5.387827',
    maxBounds: latLngBounds(latLng(50.750417, 3.358333), latLng(53.555, 7.227778)),
    zoomControl: true,
    scrollWheelZoom: true,
    dragging: true,
}

export default Map;