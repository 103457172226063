import React from "react";
import 'Style/addPin.css';
import 'Style/submitButton.css';
import 'Style/backButton.css';
import 'Style/uploadButton.css'
import 'Style/feedbackButton.css'
import 'Style/textArea.css'
import AddPinForm from "../components/layout/container/AddPinForm";
import Sidebar from "Components/layout/navigation/Sidebar";
import PhoneMenu from "Components/layout/container/PhoneMenu.js";
import 'Style/phoneMenu.css'

class AddPinView extends React.Component {
    render() {
        // document.body.className = "map-background";
        return (
            <div className="addPinPageBody">
                <Sidebar />
                <PhoneMenu/>
                <div id="addPinPage">
                    <AddPinForm />
                </div>
            </div>
        )
    }
}

export default AddPinView;