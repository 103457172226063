import React from "react";
import PropTypes from "prop-types";

class FeedbackButton extends React.Component {

    render() {
        return (
            <div>
                <input
                    type="radio"
                    id={this.props.id}
                    value={this.props.value}
                    name={this.props.name}
                    required={this.props.required}
                    defaultChecked={this.props.defaultChecked}
                />
                <label htmlFor={this.props.id} className="feedbackButton">
                    {this.props.label}
                </label>
            </div>
        );
    }
}

FeedbackButton.propTypes = {
    name: PropTypes.string.isRequired
};

export default FeedbackButton;
