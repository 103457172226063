import React from 'react';
import Icon from "Components/Icon";
import "Style/staticPin.css";
import "Style/resultsDialog.css";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ResultsDialog(props) {
    return (
        <div className="resultsDialog">
            <h1 className="headline1">{props.projectTitle}</h1>
            <section className="pinSection">
                <FontAwesomeIcon icon={faLocationDot} size="2x" color="var(--myBlue)" />
                <div className="pinTotal">{props.goodPinsTotal + props.badPinsTotal}</div>
                <FontAwesomeIcon icon={faLocationDot} size="2x" color="var(--myGreen)" />
                <div className="pinTotal">{props.goodPinsTotal}</div>
                <FontAwesomeIcon icon={faLocationDot} size="2x" color="var(--myRed)" />
                <div className="pinTotal">{props.badPinsTotal}</div>
            </section>
        </div>
    )
}

export default ResultsDialog;
