import { useMap } from 'react-leaflet';
import { LatLngBounds, Circle } from 'leaflet';

/**
 * Changes zoom level and minimum allowed zoom dynamically, based on the
 * available space. This is done, so that the amount of territory users
 * see outside the geographical bounds of the map is minimal.
 * 
 * @param {Object} props - The props for this component
 * @param {LatLngBounds} props.mapMaxBounds - The geographical bounds for the map
 * @returns {null}
 */
export default function FitBounds(props) {
    const map = useMap();
    let circle = undefined;

    if (props.projectArea) {
        map.eachLayer((layer) => {
            if (layer instanceof Circle) map.removeLayer(layer);
        })
        circle = new Circle(
            [props.projectArea.center_point_latitude, props.projectArea.center_point_longitude],
            { radius: props.projectArea.radius * 1000 }
        ).addTo(map);
    }

    const bounds = circle ? circle.getBounds() : props.mapMaxBounds
    map.setMaxBounds(bounds);
    map.fitBounds(bounds);
    if (map.zoomControl) map.setMinZoom(map.getBoundsZoom(bounds, true));
    return null;
}