import React from "react";
import {CLIENT, CLIENT_ADDRESS, CLIENT_ADDRESS_MAP_EMBEDDED, CLIENT_EMAIL, CLIENT_PHONE, CLIENT_WEBSITE, CLIENT_TEXT} from "Strings";
import { faGlobe, faAt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ContactDetails extends React.Component {

    render() {
        return (
            <div className="bodyContactDetails">
                <div className="contactDetails">
                    <h1 className="headline1">Contact Details</h1>
                    <div className="headline2">
                        <p>{CLIENT}</p>
                        <p>
                            <FontAwesomeIcon icon={faPhone} fixedWidth color="var(--mySea)" size="1x" />
                            <a href="tel:PHONE_NUM"> {CLIENT_PHONE}</a>
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faAt} fixedWidth color="var(--mySea)" size="1x" />
                            <a href = {"mailto: " + CLIENT_EMAIL}> {CLIENT_EMAIL}</a>
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faGlobe} fixedWidth color="var(--mySea)" size="1x" />
                            <a href={"https://" + CLIENT_WEBSITE}> {CLIENT_WEBSITE}</a>
                        </p>
                    </div>
                    <div className="clientPhoto"></div>
                </div>

                <div className="meetMeInPerson">
                    <h1 className="headline1">Ontmoet mij</h1>
                    <div className="headline2">
                        <p>{CLIENT_ADDRESS}</p>
                        <div className="mapClientAddress">
                            <iframe title="embeddedMap"
                                src={CLIENT_ADDRESS_MAP_EMBEDDED} frameBorder="0" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>

                <div className="aboutMe">
                    <h1 className="headline1">Over mij</h1>
                    <div className="headline2">
                        <p>{CLIENT_TEXT}</p> 
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactDetails;
