export const PROJECT_NOT_FOUND = "Geen project gevonden met de gegeven code!";
export const ACCESS_CODE_MISSING = "Graag een toegangscode invoeren!";
export const PIN_DETAILS_MISSING = "Graag de pin gegevens invoeren!";
export const PROJECT_DETAILS_MISSING = "Graag projectgegevens invoeren!";
export const INVALID_EMAIL_ENGLISH = "The email address is badly formatted. ";
export const INVALID_EMAIL_DUTCH = "Ongeldig email adres!";
export const INVALID_CREDENTIALS_ENGLISH = "There is no user record corresponding to this identifier. The user may have been deleted. ";
export const INVALID_CREDENTIALS_DUTCH = "Ongeldige inloggegevens";
export const GENERAL_ERROR_LOGIN = "Er heeft een error opgetreden bij het inloggen.";
export const UPLOAD_FILE_ERROR = "Kon bestand niet uploaden. Probeer het nogmaals!";
export const TITLE_MISSING = "Graag een titel invoeren!";
export const DESCRIPTION_MISSING = "Graag een beschrijving invoeren!";
export const START_DATE_MISSING = "Graag een startdatum invoeren!";
export const END_DATE_MISSING = "Graag een einddatum invoeren!";
export const PIN_LOCATION_MISSING = "Graag een locatie op de kaart aanklikken!";
export const END_DATE_ERROR = "De einddatum van het project moet na de startdatum komen";
export const RADIUS_MISSING = "Graag een straal invoeren!";
export const UNKNOWN_ERROR = "Er ging iets mis aan onze kant. Onze excuses daarvoor!";
