import React from "react";

class TextField extends React.Component {

    render() {
        return (
            <input
                type={this.props.type}
                id={this.props.id}
                className={this.props.className}
                name={this.props.name}
                placeholder={this.props.placeholder}
                required={this.props.required}
                onChange={this.props.onChange}
                disabled={this.props.disabled}
                hidden={this.props.hidden}
                value={this.props.value}
                readOnly={this.props.hidden ? true : this.props.readOnly}
                onInvalid={this.props.onInvalid}
            />
        );
    }
}

TextField.defaultProps = {
    type: "text",
    required: false,
    disabled: false,
    hidden: false,
};

export default TextField;
