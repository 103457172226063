import { createSlice } from "@reduxjs/toolkit";

let users = [
    {
        "id": 1,
        "email": "admin@admin.sk",
        "password": "Deventer",
    },
]

const initialState = {
    count: users.length,
    users: users,
    isAuthenticated: false
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        loginUser: (state, action) => {
            const user = {
                id: Math.random() * 100,
                email: action.payload.email,
                password: action.payload.password,
            };
            console.log("User: " + user.email, user.password)
            //Call database login and get key back, if successful, authenticate
            state.isAuthenticated = true;
        },
    },
});

export const { loginUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;