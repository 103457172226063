import React from "react";
import { useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect, isEmpty, useFirestore } from "react-redux-firebase";
import { LOADING_TEXT } from "Strings";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Map from "Components/layout/container/map/Map";
import 'Style/project.css';

const Project = (props) => {
    const auth = useSelector((state) => state.firebase.auth);
    const firestore = useFirestore();
    useFirestoreConnect([
        {
            collection: "Projects",
            doc: props.id,
            storeAs: `project-${props.id}`
        }
    ])

    const isAuthenticated = () => {
        return isLoaded(auth) && !isEmpty(auth);
    }

    const handleDeleteProject = async () => {
        if(window.confirm("Weet u zeker dat u dit project wilt verwijderen?")){
            await firestore.collection("Projects").doc(props.id).delete();  
        }
        
    }

    const project = useSelector((state) => state.firestore.data[`project-${props.id}`]);
    if (!isLoaded(project)) return <div>{LOADING_TEXT}</div>;

    return (
        <div className="projectItem">
            <section className="actionMenu">
                {isAuthenticated() &&
                    <div className="deleteIcon" onClick={handleDeleteProject}>
                        <FontAwesomeIcon icon={faTrash} color="black" size="2x" />
                    </div>}
            </section>
            <div onClick={props.onClick}>
                <h1 className="headline1">{props.name}</h1>
                <p className="body1">Access code: {props.accessCode}</p>
                <div className="map-container">
                    <Map
                        zoomControl={false}
                        scrollWheelZoom={false}
                        projectArea={project.area}
                        dragging={false}
                    />
                </div>
                <p className="menu">{props.description}</p>
            </div>
        </div>
    );
};

export default Project;