import React from "react";

class Switch extends React.Component {

    render() {
        return (
            <label className="switch">
                <input name="Switch" type="checkbox" defaultChecked={this.props.setDefaultChecked}/>
                    <span className="slider round"></span>
            </label>
        );
    }
}

export default Switch;
