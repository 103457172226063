import React from 'react'
import 'Style/feedbackPopup.css';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FeedbackPopup(props) {
  return (props.trigger) ? (
    <div className="feedbackPopup">
        <div className="popup-inner">
            <button className="close-btn" onClick={() => props.setTrigger(false)}>
                <FontAwesomeIcon icon={faXmark} color="black" size="2x" />
            </button>
            <h1>Heel erg bedankt!</h1>
            <p>Dank je voor het toevoegen van jouw mooie (of niet zo mooie) plek. De informatie wordt nu verwerkt en toegevoegd aan de kaart...</p>
            { props.children }
        </div>
    </div>
  ) : "";
}

export default FeedbackPopup
