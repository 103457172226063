import React from "react";
import Project from "Components/layout/container/Project";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import Sidebar from "Components/layout/navigation/Sidebar";
import { useNavigate } from "react-router-dom";
import { LOADING_TEXT } from "Strings";
import 'Style/addButton.css';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneMenu from "Components/layout/container/PhoneMenu.js";
import 'Style/phoneMenu.css';

export function ProjectView() {
    let navigate = useNavigate();

    useFirestoreConnect(["Projects"]);

    const projects = useSelector((state) => state.firestore.ordered.Projects);

    const goToProjectPage = (id) => {
        let path = `/projects/${id}`;
        navigate(path, {
            state: {
                projectId: id
            }
        });
    };

    if (!isLoaded(projects)) {
        return <div>{LOADING_TEXT}</div>
    }
    
    return (
        <div className="projectPageBody">
            <Sidebar/>
            <PhoneMenu/>
        <div id="projectPage">
                <div className="projects">
                    {!isEmpty(projects) ?
                        projects.map((project) => (
                            <Project
                                key={project.id}
                                id={project.id}
                                name={project.title}
                                description={project.description}
                                accessCode={project.access_code}
                                onClick={() => goToProjectPage(project.id)}
                            />
                        )) : <p>Geen projecten</p>}
                </div>
                <button name="addProjectButton" className="button-container-add-button" onClick={() => {navigate('/addProject')}}>
                    <FontAwesomeIcon icon={faPlus} size="2x" className="icon-add-button" inverse />
                </button>
        </div>
        </div>
    );
}

export default ProjectView;