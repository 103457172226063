import React from 'react'
// See: https://reactrouter.com/docs/en/v6/components/navigate
import { Navigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BackButton extends React.Component {

    constructor(props) {
        super(props);
        this.handleGoBack = this.handleGoBack.bind(this);
        this.state = { goBack: false };
    }

    handleGoBack(event) {
        event.preventDefault();
        this.setState({ goBack: true });
    }

    render() {
        return (
            <button
                onClick={this.handleGoBack}
                className="backButton">
                <FontAwesomeIcon icon={faArrowLeft} size="1x" className="backIcon" />
                Terug
                {this.state.goBack && (
                    <Navigate to={-1} replace={true} />
                )}
            </button>
        );
    }
}

export default BackButton;