import React from "react";
import PropTypes from "prop-types";

class TextArea extends React.Component {

    render() {
        return (
            <textarea 
                id={this.props.id}
                className={this.props.className}
                name={this.props.name}
                placeholder={this.props.placeholder}  
                required={this.props.required}
                onChange={this.props.onChange}
                defaultValue={this.props.defaultValue}
                wrap="hard"
                maxLength={500}
                onInvalid={this.props.onInvalid}
            />
        )
    }
}

TextArea.defaultProps = {
    required: false,
}

TextArea.propTypes = {
    name: PropTypes.string.isRequired
};

export default TextArea;
