import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from "Components/Icon";
import { isEmpty, isLoaded, useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { updatedGeneralPinDialog } from "State/generalPinDialogSlice";
import { faXmark, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "Style/pinDialog.css";

function PinDialog(props) {
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const auth = useSelector((state) => state.firebase.auth);

    const isAuthenticated = () => {
        return isLoaded(auth) && !isEmpty(auth);
    }

    const selectedPinId = useSelector(props.pinIdListener);

    const handleCloseDialog = () => {
        dispatch(props.updatePinDialog({
            pinId: null
        }))
    }

    const handleDeletePin = async () => {
        await firestore.collection("Pins").doc(selectedPinId).delete();
        handleCloseDialog();
    }

    useFirestoreConnect([
        {
            collection: "Pins",
            doc: selectedPinId,
            storeAs: `generalDialogPin-${selectedPinId}`
        }
    ])

    const selectedPin = useSelector((state) => state.firestore.data[`generalDialogPin-${selectedPinId}`]);

    if (!isLoaded(selectedPin) || isEmpty(selectedPin)) return null;

    return (
        <div className="pinDialog" hidden={selectedPinId == null}>
            <section className="actionMenu">
                {isAuthenticated() &&
                    <div className="deleteIcon" onClick={handleDeletePin}>
                        <FontAwesomeIcon icon={faTrash} color="black" size="2x" />
                    </div>}
                <div className="closeIcon" onClick={handleCloseDialog}>
                    <FontAwesomeIcon icon={faXmark} color="black" size="2x" />
                </div>
            </section>
            <h1 className="headline1">{selectedPin.title}</h1>
            <p>{selectedPin.motivation}</p>
            {selectedPin.picture_path &&
                <img src={selectedPin.picture_path}></img>}
        </div>
    )
}

export default PinDialog;