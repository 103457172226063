import {configureStore} from "@reduxjs/toolkit";
import projectReducer from "State/projectSlice";
import userReducer from "State/userSlice";
import generalPinDialogReducer from "State/generalPinDialogSlice";
import projectPinDialogReducer from "State/projectPinDialogSlice";
import generalPlaceablePinReducer from "State/generalPlaceablePinSlice";
import addProjectCenterPinReducer from "State/addProjectCenterPinSlice";
import editProjectCenterPinReducer from "State/editProjectCenterPinSlice";
import pinReducer from "State/pinSlice";
import addCircleReducer from "State/addCircleSlice";
import editCircleReducer from "State/editCircleSlice";
import {firestoreReducer} from "redux-firestore";
import {firebaseReducer} from "react-redux-firebase";


const store = configureStore({
    reducer: {
        project: projectReducer,
        generalPinDialog: generalPinDialogReducer,
        projectPinDialog: projectPinDialogReducer,
        generalPlaceablePin: generalPlaceablePinReducer,
        addProjectCenterPin: addProjectCenterPinReducer,
        editProjectCenterPin: editProjectCenterPinReducer,
        pin: pinReducer,
        addProjectCircle: addCircleReducer,
        editProjectCircle: editCircleReducer,
        firebase: firebaseReducer,
        firestore: firestoreReducer,
    },
});


export default store;