import React from 'react'
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class EditButton extends React.Component {

    render() {
        return (
            <button 
                type="submit" 
                className="buttonText submitButtonLeftMargin"
                onClick={this.props.onClick}>
                <FontAwesomeIcon icon={faPencil} size="1x" className="submitIcon" />
                {this.props.text}
            </button>
        );
    }
}

export default EditButton;