import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    circleRadius: 5
};

export const addProjectCircleSlice = createSlice({
    name: "addP rojectCircle",
    initialState,
    reducers: {
        updateProjectCircle: (state, action) => {
            state.circleRadius = action.payload.circleRadius;
        }
    },
});

export const { updateProjectCircle } = addProjectCircleSlice.actions;
export default addProjectCircleSlice.reducer;