import React from 'react';
import 'Style/sidebar.css';
import Icon from "Components/Icon";
import {useNavigate} from "react-router-dom";
import { faArrowRightToBracket, faFile, faEnvelope, faHouse, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarItem = (props) => {
    const navigate = useNavigate();

    function checkIconName () {
        switch(props.icon) {
            case 'faHouse':
              return faHouse;
            case 'faFile':
              return faFile
            case 'faEnvelope':
                return faEnvelope
            case 'faArrowRightToBracket':
                return faArrowRightToBracket
            case 'faCircleXmark':
                return faCircleXmark
            default:
              return null
          }
    }

    return (
        <li className='row' id={window.location.pathname === props.link ? 'active' : ''}
            onClick={props.onClick ? props.onClick : () => {navigate(props.link)}}>
            <div className="sidebarMenuItem">
                <div>
                    <FontAwesomeIcon icon={checkIconName()} size="xl" className="SidebarImage" />
                    {/* <Icon icon={props.icon} className='SidebarImage' size={28}/> */}
                </div>
                {props.title}
            </div>
        </li>
    )
};

export default SidebarItem;
