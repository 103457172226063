import React from "react";
import TextField from "./form/TextField";
import FeedbackButton from "./form/FeedbackButton";
import SubmitButton from "Components/layout/container/form/SubmitButton"
import TextArea from "Components/layout/container/form/TextArea";
import BackButton from "Components/layout/container/form/BackButton";
import UploadButton from "Components/layout/container/form/UploadButton";
import LocationInput from "Components/layout/container/form/LocationInput";
import Map from "Components/layout/container/map/Map";
import PlaceablePin from "Components/layout/container/map/PlaceablePin";
import { updateGeneralPlaceablePin } from 'State/generalPlaceablePinSlice';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFirebase, useFirestore } from "react-redux-firebase";
import {toast, ToastContainer} from "react-toastify";
import { DESCRIPTION_MISSING, TITLE_MISSING, PIN } from "Errors";
import {TOAST_PROPS} from "Constants/toastProps";
import {PIN_LOCATION_MISSING} from "../../../utils/constants/errors";

const storageFilePath = '/pins/';

function AddPinForm(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const firestore = useFirestore();
    const firebase = useFirebase();
    const project = props.project;

    const requiredFields = [
        'title',
        'description',
        'pinLat',
        'pinLng'
    ]

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const newPin = {
            "is_nice_place": event.target.like.value === 'true',
            "latitude": parseFloat(event.target.pinLat.value),
            "longitude": parseFloat(event.target.pinLng.value),
            "title": event.target.title.value,
            "motivation": event.target.description.value,
            "project_id": props.projectId ?? null,
            "is_private": project?.is_private ?? false,
            "visible_from": project?.end_date ?? null,
            "picture_path": null,
        }

        if (event.target.picture.files.length > 0) {
            let picture_path = storageFilePath + crypto.randomUUID();
            try {
                const pictureRef = await firebase.uploadFile(
                    picture_path,
                    event.target.picture.files[0]
                );
                pictureRef.uploadTaskSnapshot.ref.getDownloadURL()
                newPin["picture_path"] = await pictureRef.uploadTaskSnapshot.ref.getDownloadURL();
            } catch (err) {
                console.log(err)
            }
        }

        firestore.collection("Pins").add(newPin);

        // This is to make sure that the pin state is 'clean' for the next time
        // the user wants to add a general pin. Othewise the pin location would
        // carry over from the last time the form was submitted and this can lead
        // to undesirable behavior.
        dispatch(updateGeneralPlaceablePin({
            pinLat: '',
            pinLng: '',
            pinAddress: ''
        }))
        navigate('/', { state: { popup: true } });
    }

    const handleMissingField = (e, message) => {
        e.preventDefault();
        toast.error(message, TOAST_PROPS)
    }

    return (
        <form className="addPinForm" method="post" onSubmit={handleFormSubmit}>
            {project && 
                <h1 className="headline1">Welkom bij project '{project.title}'</h1>}
            {project && 
                <p>{project.description}</p> }

            <ToastContainer position="top-left"/>
            <h2 className="headline2">We zijn benieuwd naar je mening!</h2>
            <h2 className="headline2">Titel*</h2>
            <TextField
                id="titleInput"
                className="textField"
                name="title" placeholder="Titel"
                required={true}
                onInvalid={(e) => handleMissingField(e, TITLE_MISSING)}
            />
            <h2 className="headline2">Beschrijving*</h2>
            <TextArea
                id="descriptionInput"
                className="textField"
                name="description"
                placeholder="Omschrijving"
                required={true}
                onInvalid={(e) => handleMissingField(e, DESCRIPTION_MISSING)}
            />
            <h2 className="headline2">Wat vind je van deze locatie?</h2>
            <div className="feedbackButtonGroup">
                <FeedbackButton
                    id="likeButton"
                    className="feedbackButton"
                    name="like"
                    value={true}
                    label="Mooi"
                    defaultChecked={true}
                />
                <FeedbackButton
                    id="dislikeButton"
                    className="feedbackButton"
                    name="like"
                    value={false}
                    label="Niet zo mooi"
                />
            </div>
            <h2 className="headline2">Foto</h2>
            <UploadButton name="picture" />
            <h2 className="headline2">Locatie*</h2>
            <div className="map-container">
                <Map placeablePin={PlaceablePin} handleUpdatePin={updateGeneralPlaceablePin} projectArea={project?.area} />
            </div>
            <h2 className="headline2">Adres</h2>
            <LocationInput
                pinLatListener={(state) => state.generalPlaceablePin.pinLat}
                pinLngListener={(state) => state.generalPlaceablePin.pinLng}
                pinAddressListener={(state) => state.generalPlaceablePin.pinAddress}
                onInvalid={(e) => handleMissingField(e, PIN_LOCATION_MISSING)}
            />
            <div className="actionButtons">
                <BackButton />
                <SubmitButton text="Plaatsen" />
            </div>

        </form >
    )
}

export default AddPinForm;
