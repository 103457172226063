import React from 'react'
import "Style/actionButton.css";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ActionButton extends React.Component {

    render() {
        return (
            <button
                className='actionButton'
                onClick={this.props.onClick}>
                <FontAwesomeIcon icon={faLocationDot} size="1x" className="submitIcon" />
                {this.props.text}
            </button>
        );
    }
}

export default ActionButton;