import React from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { faArrowRightToBracket, faFile, faEnvelope, faHouse, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PhoneMenu() {
    const firebase = useFirebase();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.firebase.auth);
    // inital state is null to have the menu hidden without any animation on the first load of page
    const [isOpen, setOpen] = useState(null);

    const isAuthenticated = () => {
        return isLoaded(auth) && !isEmpty(auth);
    }

    return (
        <div id="menuWraper">
            <div id="phoneMenuBody" className="phoneMenu">
                <h1 className="phoneMenuTitle">Spacial Quality</h1>
                <Hamburger duration={0.5} toggled={isOpen} toggle={setOpen} ></Hamburger>
            </div>
            <div className={isOpen == null ? "my-menu-hidden" : (isOpen ? "menu-in" : "menu-out")}>
                <div className="menuItem">
                    <FontAwesomeIcon icon={faHouse} size="2x" fixedWidth inverse className="menuItemIcon" />
                    <a className="menuItemText" onClick={() => navigate('/')}>Startpagina</a>
                </div>
                {isAuthenticated() && <div className="menuItem">
                    <FontAwesomeIcon icon={faFile} size="2x" fixedWidth inverse className="menuItemIcon" />
                    <a className="menuItemText" onClick={() => navigate('/projects')}>Projecten</a>
                </div>}
                <div className="menuItem">
                    <FontAwesomeIcon icon={faEnvelope} size="2x" fixedWidth inverse className="menuItemIcon" />
                    <a className="menuItemText" onClick={() => navigate('/contact')}>Contact</a>
                </div>
                {isAuthenticated() ? <div className="menuItem">
                    <FontAwesomeIcon icon={faCircleXmark} size="2x" fixedWidth inverse className="menuItemIcon" />
                    <a className="menuItemText" onClick={() => firebase.logout().then(() => navigate('/'))}>Uitloggen</a>
                </div> :
                <div className="menuItem">
                    <FontAwesomeIcon icon={faArrowRightToBracket} size="2x" fixedWidth inverse className="menuItemIcon" />
                    <a className="menuItemText" onClick={() => navigate('/login')}>Inloggen</a>
                </div>}
            </div>
        </div>
    )
}