import React from "react";
import Sidebar from "Components/layout/navigation/Sidebar";
import ProjectForm from "../components/layout/container/ProjectForm";
import { updateProjectCenterPin } from "State/addProjectCenterPinSlice";
import { updateProjectCircle } from "State/addCircleSlice";
import "Style/addProject.css";
import "Style/textField.css";
import "Style/textArea.css";
import 'Style/switch.css';
import PhoneMenu from "Components/layout/container/PhoneMenu.js";
import 'Style/phoneMenu.css'

export function AddProjectView() {
    return (
        <div className="addProjectPageBody">
            <Sidebar/>
            <PhoneMenu/>
        <div id="addProjectPage">
            <ProjectForm
                centerPinCallback={updateProjectCenterPin}
                projectCircleCallback={updateProjectCircle}
                pinLatListener={(state) => state.addProjectCenterPin.pinLat}
                pinLngListener={(state) => state.addProjectCenterPin.pinLng}
                pinAddressListener={(state) => state.addProjectCenterPin.pinAddress}
                circleRadiusListener={(state) => state.addProjectCircle.circleRadius} />
            
        </div>
        </div>
    );
}

export default AddProjectView;