import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    circleRadius: 5
};

export const editProjectCircleSlice = createSlice({
    name: "editProjectCircle",
    initialState,
    reducers: {
        updateProjectCircle: (state, action) => {
            state.circleRadius = action.payload.circleRadius;
        }
    },
});

export const { updateProjectCircle } = editProjectCircleSlice.actions;
export default editProjectCircleSlice.reducer;