import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ContactView from "Views/contact";
import HomeView from "Views/home";
import store from "State/store";
import {Provider} from "react-redux";
import LoginView from "Views/login";
import ProjectsView from "Views/projects";
import ProjectDetailView from "Views/projectDetail";
import AddPinView from 'Views/addPin';
import AddProjectPinView from 'Views/addProjectPin';
import AddProjectView from 'Views/addProject';
import EditProjectView from 'Views/editProject';
import ProjectResultsView from 'Views/projectResultsPage';
import { getAnalytics } from "firebase/analytics";
import {ReactReduxFirebaseProvider} from 'react-redux-firebase'
import {createFirestoreInstance} from 'redux-firestore'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/database';

const fbConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

// React Redux Firebase (RRF)
const rrfConfig = {
    users: "users",
    useFirestoreForProfile: true,
}

const app = firebase.initializeApp(fbConfig);
const analytics = getAnalytics(app);
export const db = firebase.firestore();
export const firebaseFunctions = firebase.functions();
export const storage = firebase.storage();
firebase.auth();

// I think this is the same as on line 52 but I'll leave it here just in case
// export const db = getFirestore(app);

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
}


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/projects/:projectId" element={<ProjectDetailView/>}/>
                        <Route path="/projects/:projectId/results" element={<ProjectResultsView/>} />
                        <Route path="/" element={<HomeView/>}/>
                        <Route path="/contact" element={<ContactView/>}/>
                        <Route path="/login" element={<LoginView/>}/>
                        <Route path="/addproject" element={<AddProjectView />} />
                        <Route path="/projects/:id/edit" element={<EditProjectView />} />
                        <Route path="/projects" element={<ProjectsView/>}/>
                        <Route path="/addPin" element={<AddPinView/>}/>
                        <Route path="/addPin/:projectId" element={<AddProjectPinView/>}/>
                    </Routes>
                </BrowserRouter>
            </ReactReduxFirebaseProvider>
        </Provider>
    </React.StrictMode>, document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();