import React from "react";
import Sidebar from "Components/layout/navigation/Sidebar";
import ProjectForm from "../components/layout/container/ProjectForm";
import { updateProjectCenterPin } from "State/editProjectCenterPinSlice";
import { updateProjectCircle } from "State/editCircleSlice";
import "Style/addProject.css";
import "Style/textField.css";
import "Style/textArea.css";
import 'Style/switch.css';
import { LOADING_TEXT } from "Strings";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";

export function EditProjectView() {
    const dispatch = useDispatch();
    const location = useLocation();

    const projectId = location.state?.projectId ?? " ";
    console.log(projectId);

    useFirestoreConnect([
        {
            collection: "Projects",
            doc: projectId,
            storeAs: `project-${projectId}`
        }
    ])

    const project = useSelector((state) => state.firestore.data[`project-${projectId}`]);

    // Show loading message while project has not loaded yet
    if (!isLoaded(project)) return <div>{LOADING_TEXT}</div>

    return (
        <div id="addProjectPage">
            <Sidebar></Sidebar>
            <ProjectForm
                projectId={projectId}
                centerPinCallback={updateProjectCenterPin}
                projectCircleCallback={updateProjectCircle}
                pinLatListener={(state) => state.editProjectCenterPin.pinLat}
                pinLngListener={(state) => state.editProjectCenterPin.pinLng}
                pinAddressListener={(state) => state.editProjectCenterPin.pinAddress}
                circleRadiusListener={(state) => state.editProjectCircle.circleRadius}
                project={project} />
        </div>
    );
}

export default EditProjectView;