import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showDialog: false,
    pinId: null
};

export const generalPinDialogSlice = createSlice({
    name: "generalPinDialog",
    initialState,
    reducers: {
        updatedGeneralPinDialog: (state, action) => {
            state.showDialog = action.payload.showDialog;
            state.pinId = action.payload.pinId;
        }
    },
});

export const { updatedGeneralPinDialog } = generalPinDialogSlice.actions;
export default generalPinDialogSlice.reducer;