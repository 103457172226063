import React from "react";
import 'Style/addPin.css';
import 'Style/submitButton.css';
import 'Style/backButton.css';
import 'Style/uploadButton.css'
import 'Style/feedbackButton.css'
import 'Style/textArea.css'
import AddPinForm from "Components/layout/container/AddPinForm";
import Sidebar from "Components/layout/navigation/Sidebar";
import { LOADING_TEXT } from "Strings";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFirestoreConnect, useFirebase, useFirestore, isLoaded } from "react-redux-firebase";
import PhoneMenu from "Components/layout/container/PhoneMenu.js";
import 'Style/phoneMenu.css'

function AddProjectPinView(props) {

    const location = useLocation();
    const projectId = location.state?.projectId ?? " ";;
    const projectStoreName = `addPinProject-${projectId}`;

    useFirestoreConnect([
        {
            collection: "Projects",
            doc: projectId,
            storeAs: projectStoreName,
        }
    ]);

    const selectedProject = useSelector((state) => state.firestore.data[projectStoreName]);

    if (!isLoaded(selectedProject)) return <div>{LOADING_TEXT}</div>
    
    return (
        <div className="addPinPageBody">
            <Sidebar/>
            <PhoneMenu/>
            <div id="addPinPage">
                <AddPinForm project={selectedProject} projectId={projectId} />
            </div>
        </div>
    )
}

export default AddProjectPinView;