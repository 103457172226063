import React from 'react';
import 'Style/sidebar.css';
import logo from "Assets/icons/logo.png"
import SidebarItem from "./SidebarItem";
import { useNavigate } from "react-router-dom";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";

function Sidebar() {
    const firebase = useFirebase();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.firebase.auth);

    const isAuthenticated = () => {
        return isLoaded(auth) && !isEmpty(auth);
    }

    return (
        <div className="Sidebar">
            <ul className='SidebarList'>
                <div className='SidebarTop'>
                    <img src={logo} className='SidebarLogo center' alt='logo' />
                    <p className='TitleText'>Spacial Quality</p>
                </div>
                <SidebarItem title={"Startpagina"} icon={"faHouse"} link={"/"}></SidebarItem>
                {isAuthenticated() && <SidebarItem title={"Projecten"} icon={"faFile"} link={"/projects"}></SidebarItem>}
                <SidebarItem title={"Contact"} icon={"faEnvelope"} link={"/contact"}></SidebarItem>
                <div className="bottom">{isAuthenticated()
                    ? <SidebarItem
                        title={"Uitloggen"}
                        icon={"faCircleXmark"}
                        link={"/logout"}
                        onClick={() => firebase.logout().then(() => navigate('/'))}>
                    </SidebarItem>
                    : <SidebarItem
                        title={"Inloggen"}
                        icon={"faArrowRightToBracket"}
                        link={"/login"}>
                    </SidebarItem>
                }</div>
            </ul>
        </div>
    );
}

export default Sidebar;
