import Sidebar from "Components/layout/navigation/Sidebar";
import GreetingDialog from "Components/layout/container/GreetingDialog";
import Map from "Components/layout/container/map/Map";
import PinDialog from "Components/layout/container/PinDialog";
import 'Style/map.css';
import 'Style/home.css';
import 'Style/staticPin.css';
import { useSelector } from "react-redux";
import { useState } from "react";
import FeedbackPopup from "Components/layout/container/FeedbackPopup";
import { LOADING_TEXT } from "Strings";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { updatedGeneralPinDialog } from "State/generalPinDialogSlice";
import PhoneMenu from "Components/layout/container/PhoneMenu.js";
import 'Style/phoneMenu.css'


const today = new Date().toISOString();

function HomeView(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [isPopupDisplayed, setPopupDisplayed] = useState(props.popup);
    const [isGreetingDisplayed, setGreetingsDisplayed] = useState(true);

    useFirestoreConnect([
        {
            collection: "Pins",
            where: [
                ["is_private", "==", false],
                ["visible_from", "<", today]
            ],
            storeAs: "projectPins"
        },
        {
            collection: "Pins",
            where: [
                ["project_id", "==", null]
            ],
            storeAs: "generalPins"
        }
    ])

    const projectPins = useSelector((state) => state.firestore.ordered["projectPins"]);
    const generalPins = useSelector((state) => state.firestore.ordered["generalPins"]);

    if (!isLoaded(projectPins) || !isLoaded(generalPins)) { console.log("pins not loaded"); return <div>{LOADING_TEXT}</div> }

    const pins = projectPins.concat(generalPins);

    if (!pins) return <div>{LOADING_TEXT}</div>;


    function handlePopupChange() {
        navigate('/', { state: { popup: false } });
    };

    const handleOnClickPin = (pinId) => {
        dispatch(updatedGeneralPinDialog({
            pinId: pinId
        }))
    }

    return (
        <div id="homePage">
            <Sidebar/>
            <PhoneMenu/>
            <Map staticPins={pins} onStaticPinClick={handleOnClickPin} />
            <FeedbackPopup trigger={location.state?.popup} setTrigger={handlePopupChange} />
            <PinDialog
                pinIdListener={(state) => state.generalPinDialog.pinId}
                updatePinDialog={updatedGeneralPinDialog}
            />
            <GreetingDialog trigger={isGreetingDisplayed} setTrigger={setGreetingsDisplayed}/>
            <FeedbackPopup trigger={isPopupDisplayed} setTrigger={setPopupDisplayed} />
        </div>
    )
}


export default HomeView;