export const CLIENT = "Honny de Gucht - de gucht advies";
export const CLIENT_PHONE = "+31 6 22186776";
export const CLIENT_EMAIL = "honny@deguchtadvies.nl";
export const CLIENT_WEBSITE = "www.deguchtadvies.nl";
export const CLIENT_ADDRESS = "Geert Grootestraat 1, 7411 GS  DEVENTER";
export const CLIENT_ADDRESS_MAP_EMBEDDED = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2442.4141798302094!2d6.160486400000001!3d52.254023499999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7ea5d4d479ea7%3A0xbf4c1541e4226df4!2sGeert%20Grootestraat%201%2C%207411%20GS%20Deventer!5e0!3m2!1snl!2snl!4v1655807270460!5m2!1snl!2snl";
export const WELCOME_PARAGRAPGH_TEXT = "Hé! Voordat je aan de rest van de wereld vertelt wat je van allerlei locaties in Nederland vindt, moet je de toegangscode voor een specifiek project invoeren. Je kan ook een algemene pin op de kaart plaatsen, zodat iedereen hem kan zien!";
export const LOADING_TEXT = "Aan het laden...";
export const RADIUS_HINT = "Voer een straal in";
export const CLIENT_TEXT = "Mijn naam is Honny de Gucht en ik ben al meer dan 30 jaar actief in de ruimtelijke ordening. Steeds meer proberen we inwoners te betrekken bij de inrichting van hun omgeving. Wat daarbij centraal moet staan is de vraag hoe we willen dat ons land er bij ligt. Wat vinden we mooi. En wat vinden we niet zo mooi. Deze tool is een hulpmiddel om het gesprek daarover met elkaar te voeren.";
