import React from "react";
import PropTypes from 'prop-types'
import { useState } from "react";
import { useMapEvents, Marker } from 'react-leaflet';
import { latLng } from "leaflet";
import { useDispatch } from 'react-redux';
import { parseAddress, fetchAddress } from "Components/layout/container/map/util";

function isPinWithinBounds(projectArea, pinLatLng) {
    return pinLatLng.distanceTo(latLng(projectArea.center_point_latitude, projectArea.center_point_longitude)) / 1000 < projectArea.radius;
}

function PlaceablePin(props) {
    const [position, setPosition] = useState(null);
    const dispatch = useDispatch();

    const map = useMapEvents({
        click: async (event) => {
            const latLng = event.latlng;
            const address = await fetchAddress(latLng.lat, latLng.lng);

            if (!props.allowedCountries?.includes(address?.country_code)) {
                return;
            }

            if (props.projectArea && !isPinWithinBounds(props.projectArea, latLng)) {
                return;
            }

            setPosition(latLng);
            map.flyTo(latLng, map.getZoom());

            dispatch(props.handleUpdatePin({
                pinLat: latLng.lat,
                pinLng: latLng.lng,
                pinAddress: parseAddress(address)
            }));
        },
    })
    return position === null ? null : (
        <Marker position={position} />
    )
}

PlaceablePin.propTypes = {
    allowedCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PlaceablePin;