import React from 'react'
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SubmitButton extends React.Component {

    render() {
        return (
            <button
                onClick={this.props.onClick}
                type="submit"
                className="buttonText submitButtonLeftMargin">
                <FontAwesomeIcon icon={faPaperPlane} size="1x" className="backIcon" />
                {this.props.text}
            </button>
        );
    }
}

export default SubmitButton;