import React from 'react';
import { circle } from 'leaflet';
import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';

let lastCircle = undefined;

function Circle(props) {
    const map = useMap();
    const circleLat = useSelector(props.circleLatListener)
    const circleLng = useSelector(props.circleLngListener)
    const circleRadius = useSelector(props.circleRadiusListener)

    if (lastCircle !== undefined) lastCircle.remove();
    
    lastCircle = circle(
        [circleLat, circleLng],
        { radius: circleRadius * 1000 }
    ).addTo(map);
    return (
        null
    )
}

export default Circle;