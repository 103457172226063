import React from "react";
import Sidebar from "Components/layout/navigation/Sidebar";
import Map from "Components/layout/container/map/Map";
import PinDialog from "Components/layout/container/PinDialog";
import ResultsDialog from "Components/layout/container/ResultsDialog";
import { useLocation } from "react-router-dom";
import { updatedprojectPinDialog } from "State/projectPinDialogSlice";
import { useDispatch, useSelector } from "react-redux";
import { LOADING_TEXT } from "Strings";
import "Style/projectResults.css";
import PhoneMenu from "Components/layout/container/PhoneMenu";
import "Style/phoneMenu.css";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";

export default function ProjectResultsView(props) {

    const location = useLocation();
    const dispatch = useDispatch();
    const projectTitle = location.state?.projectTitle;
    const projectArea = location.state?.projectArea;
    const projectId = location.state?.projectId;
    const projectStoreName = `selectedProject-${projectId}`;
    const positivePinsStoreName = projectStoreName + "positivePins";
    const negativePinsStoreName = projectStoreName + "negativePins";

    useFirestoreConnect([
        {
            collection: "Pins",
            where: [
                ["is_nice_place", "==", true],
                ["project_id", "==", projectId],
            ],
            storeAs: positivePinsStoreName,
        },
        {
            collection: "Pins",
            where: [
                ["is_nice_place", "==", false],
                ["project_id", "==", projectId],
            ],
            storeAs: negativePinsStoreName,
        }
    ])

    const positivePins = useSelector((state) => state.firestore.ordered[positivePinsStoreName])
    const negativePins = useSelector((state) => state.firestore.ordered[negativePinsStoreName])

    const handleOnClickPin = (pinId) => {
        dispatch(updatedprojectPinDialog({
            pinId: pinId
        }))
    }

    if (!isLoaded(positivePins) || !isLoaded(negativePins)) return <div>{LOADING_TEXT}</div>

    return (
        <div id="projectResultsPage">
            <Sidebar/>
            <PhoneMenu/>
            <Map
                staticPins={positivePins.concat(...negativePins)}
                onStaticPinClick={handleOnClickPin}
                projectArea={projectArea}
            />
            <PinDialog
                pinIdListener={(state) => state.projectPinDialog.pinId}
                updatePinDialog={updatedprojectPinDialog}
            />
            <ResultsDialog
                projectTitle={projectTitle}
                goodPinsTotal={positivePins.length}
                badPinsTotal={negativePins.length}
            />
        </div>

    )
}