import React from 'react';
import PropTypes from "prop-types";
import { faPlus, faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class UploadButton extends React.Component {

    constructor(props) {
        super(props);
        this.handleFileChanged = this.handleFileChanged.bind(this);
        this.state = { fileName: "Uploaden" };
    }

    handleFileChanged(event) {
        event.preventDefault();
        this.setState({ fileName: event.target.files[0].name });
    }

    render() {
        return (
            <div>
                <label
                    htmlFor="photo_upload">
                    <FontAwesomeIcon icon={this.state.fileName === "Uploaden" ? faPlus : faCamera} size="1x" className="submitIcon" />
                    {this.state.fileName}
                </label>
                <input
                    id="photo_upload"
                    type="file"
                    className="buttonText"
                    name={this.props.name}
                    accept="image/*"
                    onChange={this.handleFileChanged}
                    onInvalid={this.props.onInvalid}
                />
            </div>

        );
    }
}

UploadButton.propTypes = {
    name: PropTypes.string.isRequired
};

export default UploadButton;