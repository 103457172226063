import React from 'react';
import TextField from "Components/layout/container/form/TextField";

import { useSelector } from 'react-redux';

function LocationInput(props) {

    const pinLat = useSelector(props.pinLatListener)
    const pinLng = useSelector(props.pinLngListener)
    const pinAddress = useSelector(props.pinAddressListener)

    return (
        <div className="locationInput">
            <TextField className="hidden-element" name="pinLat" required={true} value={pinLat} onInvalid={props.onInvalid}/>
            <TextField className="hidden-element" name="pinLng" required={true} value={pinLng} />
            <TextField className="textField" placeholder="Klik op de kaart voor een adres" disabled={true} required={true} value={pinAddress} />
        </div>
    )
}

export default LocationInput;
