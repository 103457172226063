import React from "react";
import ReactDOMServer from 'react-dom/server'
import { Marker } from 'react-leaflet';
import { latLng, divIcon } from "leaflet";
import Icon from "Components/Icon";

function StaticPin(props) {
    return (
        <Marker
            position={latLng(props.lat, props.lng)}
            icon={divIcon({
                html: ReactDOMServer.renderToString(<Icon className={props.type} icon={props.icon} size={props.size} />),
                iconAnchor: [props.size / 2, props.size],
                className: ""
            })}
            eventHandlers={{
                click: props.onClick
            }}
        />
    )
}

export default StaticPin;