import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pinLat: '',
    pinLng: '',
    pinAddress: ''
};

export const generalPlaceablePinSlice = createSlice({
    name: "generalPin",
    initialState,
    reducers: {
        updateGeneralPlaceablePin: (state, action) => {
            state.pinLat = action.payload.pinLat;
            state.pinLng = action.payload.pinLng;
            state.pinAddress = action.payload.pinAddress;
        }
    },
});

export const { updateGeneralPlaceablePin } = generalPlaceablePinSlice.actions;
export default generalPlaceablePinSlice.reducer;