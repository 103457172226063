
import React, { useState } from 'react'
import { WELCOME_PARAGRAPGH_TEXT } from "Strings";
import { useNavigate } from "react-router-dom";
import "Style/greetingDialog.css";
import { useFirestore } from 'react-redux-firebase';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UNKNOWN_ERROR, PROJECT_NOT_FOUND, ACCESS_CODE_MISSING } from "Constants/errors";
import { TOAST_PROPS } from "Constants/toastProps";
import { faCircleArrowUp, faXmark, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const today = new Date().toISOString().substring(0, 10);

export function GreetingDialog(props) {
    const navigate = useNavigate();
    const firestore = useFirestore();
    const [accessCode, setAccessCode] = useState();
    const [isGreetingDisplayed, setGreetingsDisplayed] = useState(true);

    let addProjectPin = async (e) => {
        e.preventDefault();
        try {
            firestore.get({
                collection: "Projects",
                where: [
                    ["access_code", "==", accessCode],
                    ["is_private", "==", false],
                    // As for 2022-06-26 inequality queries in Firebase can be only against one variable
                    ["end_date", ">=", today], // check if the end date of project is in the future
                ]
            }).then((res) => res.docs).then((docs) => {
                // Check if start date of project was in the past and see if Firebase returned any documents
                if (docs.length > 0 && docs[0].data().start_date <= today) {
                    const projectId = docs[0].id
                    navigate(`/addPin/${projectId}`, {
                        state: {
                            projectId: projectId
                        }
                    });
                } else {
                    toast.error(PROJECT_NOT_FOUND, TOAST_PROPS);
                }
            })
        } catch (e) {
            toast.error(UNKNOWN_ERROR, TOAST_PROPS);
        }
    }

    const handleAccessCodeChange = (event) => {
        setAccessCode(event.target.value);
    };

    const handleAccessCodeNotPresent = (e) => {
        e.preventDefault();
        toast.warn(ACCESS_CODE_MISSING, TOAST_PROPS);
    }

    return (
        <>
            <ToastContainer position="top-left" />
            {isGreetingDisplayed ? <div id="greetingDialog">
                <button className="close-btn" onClick={() => setGreetingsDisplayed(false)}>
                    <FontAwesomeIcon icon={faXmark} color="black" size="1x" />
                </button>
                <h1>Welkom bij <br /> Spacial Quality!</h1>
                <p>{WELCOME_PARAGRAPGH_TEXT}</p>
                <form id="accessCodeInput" onSubmit={addProjectPin}>
                    <input type="text" name="accessCode" placeholder="Toegangscode" onChange={handleAccessCodeChange} onInvalid={handleAccessCodeNotPresent} required />
                    <button type="submit" className="button-container-join" >NAAR PROJECT</button>
                </form>
                <div className="orBlock">
                    <hr />
                    <div className="orText">of</div>
                    <hr />
                </div>
                <p>
                    <button className="button-container-create" onClick={() => { navigate('/addPin') }}>
                        ALGEMENE PIN PLAATSEN&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faLocationDot} size="xl" />
                    </button>
                </p>
            </div> :
                <div id="greetingDialog">
                    <button className="open-btn" onClick={() => (setGreetingsDisplayed(true))}>
                        <FontAwesomeIcon icon={faCircleArrowUp} color="black" size="2x" />
                    </button>
                </div>}
        </>
    )
}

export default GreetingDialog;
