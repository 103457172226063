import React, { useState } from "react";
import 'Style/login.css';
import Sidebar from "Components/layout/navigation/Sidebar";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import PhoneMenu from "Components/layout/container/PhoneMenu.js";
import 'Style/phoneMenu.css'
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {TOAST_PROPS} from "Constants/toastProps";
import {
    INVALID_CREDENTIALS_DUTCH,
    INVALID_CREDENTIALS_ENGLISH,
    INVALID_EMAIL_DUTCH,
    INVALID_EMAIL_ENGLISH
} from "Errors";
import {GENERAL_ERROR_LOGIN} from "../utils/constants/errors";

function LoginView() {
    const initialUserState = { "email": "", "password": "" }
    const [user, setUser] = useState(initialUserState);
    let navigate = useNavigate();
    const firebase = useFirebase();

    const handleEmailChange = e => setUser({ ...user, email: e.target.value })
    const handlePasswordChange = e => setUser({ ...user, password: e.target.value })

    const handleLoginUser = async () => {
        return firebase.login({
            email: user.email,
            password: user.password
        }).then(() => navigate("/")).catch(error => {
            let errorMessage = error.message.replace('Firebase: ', '').replace(/\(auth.*\)\.?/, '');
            let errorDutch;

            switch(errorMessage){

                case INVALID_EMAIL_ENGLISH:
                    errorDutch = INVALID_EMAIL_DUTCH;
                    break;
                case INVALID_CREDENTIALS_ENGLISH:
                    errorDutch = INVALID_CREDENTIALS_DUTCH;
                    break;
                default:
                    errorDutch =  GENERAL_ERROR_LOGIN;
            }
            toast.error(errorDutch, TOAST_PROPS);
        });
    }

    document.body.className = "map-background";

    return (
        <div className="loginPageBody">
            <Sidebar/>
            <PhoneMenu/>
        <div id="loginPage">
            <ToastContainer position="top-left"/>
            <div className="loginForm">
                <h1 className="subheader">Login to Spatial Quality</h1>
                <form>
                    <div className="input-container">
                        <input type="email" name="email" placeholder="Email" value={user.email} onChange={handleEmailChange} required />
                    </div>
                    <div className="input-container">
                        <input type="password" name="password" placeholder="Password" value={user.password} onChange={handlePasswordChange} required />
                    </div>
                    {/* <a className="input-container" href="#">Forgot your password?</a> */}
                    <div className="button-container">
                        <input
                            type="submit"
                            value="Login"
                            onClick={(e) => {
                                e.preventDefault();
                                handleLoginUser();
                            }}
                        />
                    </div>
                </form>
            </div>
        </div>
        </div>
    )
}

export default LoginView;