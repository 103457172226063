import React, { useState } from "react";
import TextField from "Components/layout/container/form/TextField";
import TextArea from "Components/layout/container/form/TextArea";
import BackButton from "Components/layout/container/form/BackButton";
import SubmitButton from "Components/layout/container/form/SubmitButton"
import LocationInput from "Components/layout/container/form/LocationInput";
import Switch from "Components/layout/container/form/Switch"
import Map from "Components/layout/container/map/Map"
import PlaceablePin from "Components/layout/container/map/PlaceablePin";
import Circle from "Components/layout/container/map/Circle";
import Stack from '@mui/material/Stack';
import { updateProjectCenterPin } from "State/editProjectCenterPinSlice";
import { updateProjectCircle } from "State/editCircleSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_PROPS } from "Constants/toastProps";
import { RADIUS_HINT } from "Strings";
import {
    DESCRIPTION_MISSING,
    TITLE_MISSING,
    PIN, START_DATE_MISSING,
    END_DATE_MISSING,
    ACCESS_CODE_MISSING,
    RADIUS_MISSING,
    PIN_LOCATION_MISSING
} from "Errors";


function ProjectForm(props) {
    const navigate = useNavigate();
    const firestore = useFirestore();
    const dispatch = useDispatch();

    const handleUpdateRadius = (e) => {
        dispatch(props.projectCircleCallback({
            circleRadius: e.target.value
        }));
    }

    const project = props.project;

    const [projectName, setProjectName] = useState(project?.title ?? "");
    const [projectDescription, setProjectDescription] = useState(project?.description ?? "");
    const [startDate, setStartDate] = useState(project?.start_date ?? "");
    const [endDate, setEndDate] = useState(project?.end_date ?? "");
    const [accessCode, setAccessCode] = useState(project?.access_code ?? "");

    if (project) {
        dispatch(updateProjectCircle(
            {
                circleRadius: project.area.radius
            }
        ));


        dispatch(updateProjectCenterPin(
            {
                pinLat: project.area.center_point_latitude,
                pinLng: project.area.center_point_longitude,
                pinAddress: project.area.center_point_address
            }
        ));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const startDate = e.target.StartDate.value;
        const endDate = e.target.EndDate.value;

        if (endDate < startDate) {
            alert("End date must be after start date");
            return;
        }

        const newProject = {
            title: projectName,
            description: projectDescription,
            start_date: startDate,
            end_date: endDate,
            access_code: accessCode,
            is_private: !e.target.Switch.checked,
            area: {
                center_point_latitude: parseFloat(e.target.pinLat.value),
                center_point_longitude: parseFloat(e.target.pinLng.value),
                radius: parseInt(e.target.Radius.value) 
            }
        }

        if (!project) firestore.collection("Projects").add(newProject);
        else firestore.collection("Projects").doc(props.projectId).update(newProject);

        navigate('/projects')
    };

    const handleMissingField = (e, message) => {
        e.preventDefault();
        toast.error(message, TOAST_PROPS)
    };

    return (
        <form className="addProjectForm" method="post" onSubmit={handleSubmit}>
            <ToastContainer position="top-left" />
            <div className="addProjectRow">
                {project ?
                    <h1 className="headline1">Project {project.title} wijzigen</h1>
                    :
                    <h1 className="headline1">Maak een nieuw project</h1>
                }
            </div>
            <div className="addProjectRow">
                <section className="descriptionSection">
                    <div className="inputs">
                        <h2 className="headline2">Titel*</h2>
                        <TextField
                            name="Title"
                            id="titleInput"
                            className="textField"
                            placeholder="Titel"
                            required={true}
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            onInvalid={(e) => handleMissingField(e, TITLE_MISSING)}
                        />
                    </div>
                    <div className="inputs">

                        <h2 className="headline2">Omschrijving*</h2>
                        <TextArea
                            name="Description"
                            id="descriptionInput"
                            className="textField"
                            placeholder="Omschrijving"
                            required={true}
                            defaultValue={projectDescription}
                            onChange={(e) => setProjectDescription(e.target.value)}
                            onInvalid={(e) => handleMissingField(e, DESCRIPTION_MISSING)}
                        />
                    </div>
                    <h2 className="headline2">Datums</h2>
                    <div className="menu">
                        <div className="inputs">
                            <p>Start datum:*</p>
                            <input
                                name="StartDate"
                                className="textField"
                                type="date"
                                value={startDate}
                                required={true}
                                onChange={(e) => setStartDate(e.target.value)}
                                onInvalid={(e) => handleMissingField(e, START_DATE_MISSING)}
                            />
                            <br />
                            <p>Eind datum:*</p>
                            <input
                                name="EndDate"
                                className="textField"
                                type="date"
                                value={endDate}
                                required={true}
                                onChange={(e) => setEndDate(e.target.value)}
                                onInvalid={(e) => handleMissingField(e, END_DATE_MISSING)}
                            />
                        </div>
                    </div>
                    <h2 className="headline2">Toegangscode*</h2>
                    <TextField
                        name="AccessCode"
                        id="accessCodeInput"
                        className="textField"
                        placeholder="Toegangscode"
                        required={true}
                        value={accessCode}
                        onChange={(e) => setAccessCode(e.target.value)}
                        onInvalid={(e) => handleMissingField(e, ACCESS_CODE_MISSING)}
                    />
                    <h2 className="headline2">Toegang*</h2>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <p>Privé</p>
                        <Switch setDefaultChecked={project ? !project.is_private : true} />
                        <p>&nbsp;Publiek</p>
                    </Stack>
                </section>
                <section className="areaSection">
                    <h2 className="headline2">Area</h2>
                    <p className="menu">Radius:*</p>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <input
                            name="Radius"
                            type="number"
                            className="textField"
                            required={true}
                            min="1"
                            placeholder={RADIUS_HINT}
                            onChange={handleUpdateRadius}
                            defaultValue={project ? project.area.radius : ''}
                            onInvalid={(e) => handleMissingField(e, RADIUS_MISSING)} />
                        <p>&nbsp;km</p>
                    </Stack>
                    <div className="map-container">
                        <Map
                            placeablePin={PlaceablePin}
                            handleUpdatePin={props.centerPinCallback}
                            circle={Circle}
                            circleLatListener={props.pinLatListener}
                            circleLngListener={props.pinLngListener}
                            circleRadiusListener={props.circleRadiusListener} />
                    </div>
                    <p className="menu">Adres:*</p>
                    <LocationInput
                        pinLatListener={props.pinLatListener}
                        pinLngListener={props.pinLngListener}
                        pinAddressListener={props.pinAddressListener}
                        onInvalid={(e) => handleMissingField(e, PIN_LOCATION_MISSING)}
                    />
                    <div className="actionButtons">
                        <BackButton />
                        <SubmitButton text="Plaatsen" />
                    </div>
                </section>
            </div>
        </form >
    )
}

export default ProjectForm;