export function parseAddress(fullAddress) {
    let addressString = '';
    addressString += fullAddress.road ? fullAddress.road + ' ' : '';
    addressString += fullAddress.house_number ? fullAddress.house_number + ', ' : '';
    addressString += fullAddress.postcode ? fullAddress.postcode + ' ' : '';
    addressString += fullAddress.city ?? fullAddress.town ?? fullAddress.village ?? '';
    return addressString ? addressString : 'Sorry, we kunnen dat adres niet vinden!';
}

export async function fetchAddress(lat, lng) {
    const res = await (await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`)).json();
    return res.address;
}
