import React from "react";
import ContactDetails from "Components/layout/container/ContactDetails";
import 'Style/contact.css';
import Sidebar from "Components/layout/navigation/Sidebar.js";
import PhoneMenu from "Components/layout/container/PhoneMenu.js";
import 'Style/phoneMenu.css'

class ContactView extends React.Component {
    render() {
        document.body.className = "map-background";
        return (
            <div id="contactPage">
                <Sidebar/>
                <PhoneMenu/>
                <ContactDetails />
            </div>
        )
    }
}
export default ContactView;