import React from "react";
import Sidebar from "Components/layout/navigation/Sidebar";
import Map from "Components/layout/container/map/Map";
import "Style/projectDetail.css";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { LOADING_TEXT } from "Strings";
import BackButton from "Components/layout/container/form/BackButton";
import EditButton from "Components/layout/container/form/EditButton"
import ActionButton from "Components/layout/container/form/ActionButton";
import PhoneMenu from "Components/layout/container/PhoneMenu.js";
import 'Style/phoneMenu.css';

const ProjectDetailView = () => {

    const location = useLocation();
    const navigate = useNavigate();
    // This little 'hack' is necessary because of the way Javascript is built ¯\_(ツ)_/¯
    // What happens here is that if projectId is falsy, it won't be passed to
    // the useFirestoreConnect hook as the value for 'doc'. This will cause
    // the hook to retrieve all projects instead of none, which is defintely
    // not desirable. In order to bypass that, we set projectId to " " (notice
    // the whitespace character), so that the value is truthy and no project is
    // retrieved.
    const projectId = location.state?.projectId ?? " ";
    const projectStoreName = `selectedProject-${projectId}`

    useFirestoreConnect([
        {
            collection: "Projects",
            doc: projectId,
            storeAs: projectStoreName
        }
    ])

    const goToProjectResults = () => {
        navigate("results", {
            state: {
                projectTitle: selectedProject.title,
                projectId: projectId,
                projectArea: selectedProject.area
            }
        })
    }

    const selectedProject = useSelector((state) => state.firestore.data[projectStoreName]);

    // Show loading message while project has not loaded yet
    if (!isLoaded(selectedProject)) return <div>{LOADING_TEXT}</div>

    return (
        <div className="projectDetailPageBody">
            <Sidebar />
            <PhoneMenu />
            <div id="projectDetailPage">
                {!isEmpty(selectedProject) ?
                    <div className="projectCard">
                        <div className="projectDetailsRow">
                            <h1 className="headline1">{selectedProject.title}</h1>
                        </div>
                        <div className="projectDetailsRow">
                            <section className="descriptionSection">
                                <p className="menu">{selectedProject.description}</p>

                                <h2 className="headline2">Dates</h2>
                                <div className="menu">
                                    <p>Start: {selectedProject.start_date}</p>
                                    <p>Finish: {selectedProject.end_date}</p>
                                </div>

                                <h2 className="headline2">Access code</h2>
                                <p className="menu">{selectedProject.access_code}</p>

                                <h2 className="headline2">Accessibility</h2>
                                <p className="menu">Project is set to
                                    <span className="headline2">{selectedProject.is_private ? " private" : " public"}</span></p>
                            </section>

                            <section className="areaSection">
                                <h2 className="headline2">Area</h2>
                                <p className="menu">Radius: {selectedProject.area.radius}km</p>
                                <div className="map-container">
                                    <Map
                                        zoomControl={false}
                                        projectArea={selectedProject.area}
                                        scrollWheelZoom={false}
                                        dragging={false}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="projectDetailsRow alignRowRight">
                            <section className="buttonsSection">
                                <div className="actionButtons">
                                    <BackButton />
                                    <EditButton text="Bewerken" onClick={(e) => navigate(`edit`, { state: { projectId: projectId } })} />
                                    <ActionButton
                                        text="Resultaten"
                                        icon={"resultsPin"}
                                        onClick={() => goToProjectResults()}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                    : <Navigate to="/" />
                }
            </div>
        </div>
    );
}

export default ProjectDetailView;
